
import Products from "./Products";
import styled from "styled-components";
import FeatureProducts from "./featureProduct";
import { Helmet } from "react-helmet-async";
import Foots2 from "./foot2";


const Home = () => {

  return (
    <>
      <Helmet>
        <title>lanhnb.store</title>
      </Helmet>
      <Wrapper>
        <div className="ho">
      <div className="row" style={{marginLeft:"auto", marginRight:"auto"}}><Products /></div>
      <div className="row"><FeatureProducts /></div>
      </div>
      </Wrapper>
      <Foots2/>
      
    
        
        
         </>
  )
}


export default Home;
const Wrapper = styled.div`
.ho{
  background: linear-gradient(141deg, white 0%, #ffebcc 51%,   #adebeb 85%);
}

`;
