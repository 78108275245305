import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { createAsyncThunk } from "@reduxjs/toolkit";
import PageNavigation from "./PageNavigation"
import MyImage from "./MyImage";
import FormatPrice from "./FormatPrice";
import Star from "./Star";

// for rating
import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
//end rate

import { useState, useEffect } from "react";

import axios from "axios";
import { setHeaders } from "./api";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addToCart } from "./slices/cartSlice";
import { updatedProduct } from "./slices/productsSlice";

import { toast } from 'react-toastify';
import { getError } from './payment/utils';
import Foots2 from "./foot2";
import { useSelector } from "react-redux";

const SingleProduct = () => {
  const url = "https://lanhnb.click/api"
  // const url = "http://localhost:8000/api"

  const params = useParams();
  const [product, setProduct] = useState({});
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visits, setVisits] = useState(0);




  const { _id: alias, name, price, priceOff, description, stock, stars, image, category, info, reviews } = product;

  useEffect(() => {
    setloading(true)

    setVisits(visits=>visits + 1);
    async function fetchData() {
      try {
        const res = await axios.get(`${url}/products/find/${params.id}`, setHeaders());

        setProduct(res.data);

      } catch (err) {
        console.log(err)
      }
      setloading(false)

    }
    fetchData()
  }, [params.id])

  // const storedVisits = Number(localStorage.getItem("visitCounter")) || 0;
  // // //   Saving in localStorage
  // useEffect(() => {
  //   localStorage.setItem("visitCounter", visits);
  // }, [visits]);
  const vie = reviews + visits;

  const ProductsEdit = () => {

    dispatch(
      updatedProduct({
        product: {
          _id: alias,
          reviews: vie,
        }

      })
    );
    
  };





  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    navigate("/cart")
  }
  // for rating start

  const labels = {
    0.5: 'Quá tệ',
    1: 'Quá tệ+',
    1.5: 'Kém',
    2: 'Kém+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Tốt',
    4: 'Tốt+',
    4.5: 'Rất tốt',
    5: 'Rất tốt+',
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [namec, setNamec] = React.useState("");
  const [hideFR, sethideFR] = useState(true)
  const [isShownRM, setIsShownRM] = useState(false);
  const ReadMore = () => { setIsShownRM(current => !current); }
  const [emailorPhone, setEmailorPhone] = React.useState("");
  const [comment, setComment] = React.useState("");

  // for rate from

  const isTextareaDisable = comment.length === 0;

  const handleComment = async (e) => {
    e.preventDefault();
    try {

      const { data } = await axios.post(`${url}/comment`,
        {
          namec,
          emailorPhone,
          comment,
          rate: value,

        }, setHeaders()

      );
      console.log(data)
      toast.info("Mess success");

    } catch (err) {
      toast.error(getError(err));
      console.log(getError(err))

    }


  };




  return (


    <Wrapper>
      <Helmet>
        <title>{name}</title>
        <meta name="keywords" content="Laptop, xuất khẩu lao động, Bất động sản "/>
        <meta name="description" content="Laptop, xuất khẩu lao động, Bất động sản"/>
        <meta name="author" content="lanhnb"/>
      </Helmet>

      {loading ? (
        <p> Loading...</p>
      ) : (
        <>

          <div id="home11" onLoad={ProductsEdit}>

            <div className="w3-container">
              <PageNavigation title={name} />
              <div className="w3-col m6 s12">
                <div className="product-images1">
                  {/* <img src={image?.url} alt={name}/> */}
                  <MyImage imgs={image} />

                </div>
                <div className="w3 container rate">
                  <div className="row">


                    <form onSubmit={handleComment} style={{ border: "1px solid #dee2e6", borderRadius: "2px", marginTop: "20px" }}>
                      <div className="w3 d-flex">
                        <i style={{ marginLeft: "20px" }}> Gửi cho Chúng tôi đánh giá của bạn &nbsp;</i>
                        <Box
                          sx={{
                            width: 200,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Rating
                            name="hover-feedback"
                            value={value}
                            precision={0.5}
                            getLabelText={getLabelText}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              setHover(newHover);
                            }}
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                          />
                          {value !== null && (
                            <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
                          )}
                        </Box>

                      </div>
                      <div className="form-row">
                        <div className="w3 d-flex">
                          <div className="col col-md-6" style={{ margin: "2px" }}>

                            <textarea value={comment} onChange={(e) => setComment(e.target.value)} class="form-control" id="exampleFormControlTextarea1" placeholder="Đánh giá sản phẩm" rows="3"></textarea>
                          </div>
                          <div className="col-md-6" style={{ margin: "2px", }} >
                            <div className="w3 d-flex">

                              <div className="col col-md-6" style={{ margin: "2px", width: "49%" }}>

                                <input type="text" class="form-control" id="inputPassword4" placeholder="Tên" onChange={(e) => setNamec(e.target.value)}
                                  required />
                              </div>
                              <div className="col col-md-6" style={{ margin: "2px", width: "49%" }}>
                                <input type="text" class="form-control" id="inputPassword4" placeholder="Email | số điện thoại " onChange={(e) => setEmailorPhone(e.target.value)}
                                  required />
                              </div>
                            </div>

                            <div style={{ margin: "2px" }}>
                              <button disabled={isTextareaDisable} style={{ border: "1px solid #dee2e6", width: "100%", borderRadius: "5px" }} >Gửi</button>
                            </div>

                          </div>


                        </div>


                      </div>


                    </form>
                  </div>

                </div>
              </div>
              <div className="w3-col m6 s12">
                <div className="product-data">
                  <h1 id="named">{name}</h1>
                  <Star stars={stars} views={reviews} />


                  <p className="product-data-price">
                    MRP:
                    <del>
                      <FormatPrice price={price} />
                    </del>
                  </p>
                  <p className="product-data-price product-data-real-price">
                    Giá khuyến mại: <FormatPrice price={priceOff} />

                  </p>
                  <p className="des">{description}...</p>
                  <p>Trong kho: <b>{stock}</b></p>
                  <p>ID: <b>{alias}</b></p>
                  <div className="w3 d-flex" >
                    <div>
                      <p>Loại sản phẩm: <b>{category}</b></p>
                    </div>
                    <div style={{ float: "right", marginLeft: "50px" }}>
                      <button className="btnRe1" onClick={ReadMore} ><span onClick={() => sethideFR(!hideFR)}>{hideFR ? "Xem thêm..." : "thu gọn..."}</span></button>
                    </div>
                  </div>
                  <hr />
                  <button className="btnRe" onClick={() => handleAddToCart(product)}>
                    Thêm vào giỏ hàng
                  </button>
                </div>
                <div className="khoang" style={{ "height": "100px" }}>
                </div>
              </div>

            </div>

          </div>
        </>


      )
      }
      {
        isShownRM && (
          <div className="w3 container">

            <div id="more3" className="col-md-12">

              <div dangerouslySetInnerHTML={{ __html: (info) }} />

            </div>
          </div>
        )}
      <Foots2 />
    </Wrapper >

  )
};


const Wrapper = styled.section`
span.MuiRating-decimal.css-34he1w-MuiRating-decimal {
  font-size: 23px;
}
.btnRe1{
  background:none;
  border:none;
   color: gray;
  
}
.btnRe1:hover{
   color: ${({ theme }) => theme.colors.btn};
   font-style: italic;
 }

 #more3 {
  margin-top:30px;
 
  img {
      max-width: 100%;
       height: auto;
  }
  p {
      justify-content: space-between;
  }
}

#named{
  text-transform: capitalize;
  font-variant-caps: all-petite-caps;
  color: #285ea6;
  font-size:30px;
}
@media screen and (min-width: 769px) {
  .grid-two-column{
    display: grid;
    grid-template-columns: 1fr 1fr;
    img{
      width:453px;
    }
  }
}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) { 
    /* STYLES HERE */
}

@media only screen and (max-device-width: 480px) {
  .khoang{
    display:none;
  }
  
  }
  
  
  .grid-two-column{
    display: grid;
    grid-template-columns: 1fr 1fr;
    img{
      width:453px;
    }
  }
  
  .product-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left:50px;
    gap: 2rem;
    .des{
      text-align: justify;
    
    }
    hr{
      max-width: 100%;
      width: 90%;
      /* height: 0.2rem; */
      border: 0.1rem solid red;
      margin-top:-15px;
      
    }
    .btn{
      background-color: ${({ theme }) => theme.colors.btn};
      padding:5px;
      color:#fff;
      font-size: 15px;
    }
  }

    

    .product-data-warranty {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;

      .product-warranty-data {
        text-align: center;

        .warranty-icon {
          background-color: rgba(220, 220, 220, 0.5);
          border-radius: 50%;
          width: 4rem;
          height: 4rem;
          padding: 0.6rem;
        }
        p {
          font-size: 1.4rem;
          padding-top: 0.4rem;
        }
      }
    }

    .product-data-price {
      font-weight: bold;
    }
    .product-data-real-price {
      color: ${({ theme }) => theme.colors.btn};
    }
    .product-data-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 1.8rem;

      span {
        font-weight: bold;
      }
    }

    
  }

  .product-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 0 2.4rem;
  }
  .btnRe{
    background-color:rgb(0 0 0 /0%);
    border: 0.1rem solid rgb(98 84 243); 
    color: gray;
    padding:5px;
    border-radius:5px;
    margin-top:-24px;
}
.btnRe:hover{
  background-color: ${({ theme }) => theme.colors.btn};
  color:white;
}
`;
export default SingleProduct;