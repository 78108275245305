import React from 'react'
import UserList from '../list/Userlist'

function Users(){
   
    return(
        <UserList/>

    )
}
 export default Users