import { Helmet } from "react-helmet-async";
import Foots2 from "./foot2";
import styled from "styled-components";
import XkldScreen from "./XkldScreen";


const XkldHome = () => {

  return (
    <>
      <Helmet>
        <title>lanhnb.store</title>
      </Helmet>
      <Wrapper>
      <div className="row"><XkldScreen /></div>
      </Wrapper>
      <Foots2/>
      
    
        
        
         </>
  )
}


export default XkldHome;
const Wrapper = styled.div`
background: linear-gradient(141deg, white 0%,#f7e6ff 51%,   #adebeb 85%);
`;