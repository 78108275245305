import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../slices/authSlice";
import { useNavigate } from "react-router-dom";
import { StyledForm } from "./StyledForm";
import Foots2 from "../foot2";
import styled from "styled-components";



const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (auth._id) {
      navigate("/admin/summary");

    }
  }, [auth._id, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(user);
    dispatch(loginUser(user));
  };

  return (
    <><Wrapper>
     
        <StyledForm onSubmit={handleSubmit}>
        <div id="home11" className="w3-col m12 s12 w3-container contt">

          <h2>Login</h2>
          <input
            type="email"
            placeholder="email"
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
          <input
            type="password"
            placeholder="password"
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          />
          <button>
            {auth.loginStatus === "pending" ? "Submitting..." : "Login"}
          </button>
          {auth.loginStatus === "rejected" ? <p>{auth.loginError}</p> : null}
          </div >
        </StyledForm>
        <div className="khoangtrong">

        </div>
      
      <Foots2 />
    </Wrapper>
    </>

  );
};

export default Login;

const Wrapper = styled.div`
  background: linear-gradient(141deg, white 0%, #ffebcc 51%,   #adebeb 85%);
@media screen and (max-width: 2468px) {
  .khoangtrong{
    height:930px;
}
@media screen and (max-width: 480px) {
  .khoangtrong{
    height:652px;
  }
`;