
import Products from "./Products";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import Foots2 from "./foot2";


const Shop = () => {

  return (
    <>
      <Helmet>
        <title>lanhnb.store</title>
      </Helmet>
      <Wrapper>
      <div className="row" style={{marginLeft:"auto", marginRight:"auto"}}><Products /></div>
      </Wrapper>
      <Foots2/>
      
    
        
        
         </>
  )
}


export default Shop;
const Wrapper = styled.div`
background: linear-gradient(141deg, white 0%, #ffebcc 51%,   #adebeb 85%);
`;