import moment from 'moment';
import FormatPrice from "./FormatPrice";
import { NavLink } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { addToCart } from "./slices/cartSlice";
// import { useNavigate, useParams } from "react-router-dom";
// import { useState, useEffect } from "react";
import View from "./View";
import styled from "styled-components"


const Product = (product) => {

    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    // const handleAddToCart = (product) => {
    //     dispatch(addToCart(product));
    //     navigate("/cart")
    // }
    const { _id, name, image, price, priceOff, category, createdAt, stock, reviews } = product;


    return (
        <>
       

            <div className="w3 border-bottom cart" >

                <figure>
                    <NavLink to={`/singleproduct/${_id}`}>
                        <img src={image?.[0].url} alt={name} />
                        {price - priceOff > 0 ? (<figcaption className="caption">Off:{((price - priceOff) / price * 100).toFixed(2)}% </figcaption>) : ("")}

                    </NavLink>
                </figure>


                <div className="card-data-flex">

                    <p > <b className="name" style={{marginLeft:"5px", fontSize:"11px"}}>{name}</b> </p>
                    <p className="gia" style={{marginRight:"3px"}}><i class="fa-thin fa-circle-dollar"></i> <b style={{color:"red", fontSize:"11px"}}>{<FormatPrice price={price} />}</b><img src='https://res.cloudinary.com/dxnhv54sl/image/upload/v1704876493/logo/s3wudnqv1q2axmhfv6i7.png' alt={price} style={{width:"15px", marginLeft:"3px"}} /> </p>
                    
                    
                   
                </div>
                <div className='card-data-flex'>
                <p > <i style={{marginLeft:"5px", fontSize:"11px"}}> <img src='https://res.cloudinary.com/dxnhv54sl/image/upload/v1704875724/logo/kbitiot4dn4mnytqpkbf.png' alt={price} style={{width:"13px", marginRight:"5px"}} />{moment(createdAt).format('D/M/Y')}</i></p>
                <p style={{marginRight:"5px", fontSize:"8px"}}><View views={reviews} /></p>
                </div>
               
                {/* <div className="btnC">
                    <button className="btn" onClick={() => handleAddToCart(product)}>
                        Add to Cart
                    </button>
                    </div> */}
            </div>


           

        </>
    )
};
export default Product;






