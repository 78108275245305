import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  clearCart,
  decreaseCart,
  getTotals,
  removeFromCart,
} from "./slices/cartSlice";
import { Helmet } from "react-helmet-async";

import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import PayButton from "./payment/PayButton";
import { height } from "@mui/system";
import FormatPrice from "./FormatPrice";

const Cart = () => {
  const cart = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.auth);
 

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };
  const handleClearCart = () => {
    dispatch(clearCart());
  };
  const checkoutHandler = () => {
    navigate('/shipping');
  };
  return (
    <>
    <Helmet>
      <title>lanhnb.store</title>
    </Helmet>
    <div id="home11" className="cart-container">
      <h2>Quản lý giỏ hàng</h2>
      {cart.cartItems.length === 0 ? (
        <div className="cart-empty">
          <p>Giỏ hàng rỗng</p>
          <div className="start-shopping">
            <Link to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              <span>Bắt đầu mua hàng</span>
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <div className="titles">
            <h3 className="product-title">Sản phẩm</h3>
            <h3 className="price">Giá</h3>
            <h3 className="quantity">Số lượng</h3>
            <h3 className="total">Tổng</h3>
          </div>
          <div className="cart-items">
            {cart.cartItems &&
              cart.cartItems.map((cartItem) => (
                <div className="cart-item" key={cartItem._id}>
                  <div className="cart-product">
                    <img src={cartItem.image[0]?.url} alt={cartItem.name}  />
                    <div>
                      <h3>{cartItem.name}</h3>
                      <p>{cartItem.desc}</p>
                      <button onClick={() => handleRemoveFromCart(cartItem)}>
                        Xóa sản phẩm
                      </button>
                    </div>
                  </div>
                  <div className="cart-product-price"> <FormatPrice price= {cartItem.price}/></div>
                  <div className="cart-product-quantity">
                    <button onClick={() => handleDecreaseCart(cartItem)}>
                      -
                    </button>
                    <div className="count">{cartItem.cartQuantity}</div>
                    <button onClick={() => handleAddToCart(cartItem)}>+</button>
                  </div>
                  <div className="cart-product-total-price">
                  <FormatPrice price={cartItem.price * cartItem.cartQuantity}/>
                  </div>
                </div>
                
              ))}
          </div>
          <div className="cart-summary">
            <button className="clear-btn" onClick={() => handleClearCart()}>
             Xóa giỏ hàng
            </button>
            <div className="cart-checkout">
              <div className="subtotal">
                <span>Tổng</span>
                <span className="amount"> <FormatPrice price= {cart.cartTotalAmount}/></span>
              </div>
              <p>Thuế và và chi phí giao hàng</p>
              {auth._id ? (
                // <PayButton cartItems={cart.cartItems} />
                <Button
                      type="button"
                      variant="primary"
                      onClick={checkoutHandler}
                      disabled={cart.cartItems.length === 0}
                    >
                      Mua hàng
                    </Button>
              ) : (
                <button
                  className="cart-login"
                  onClick={() => navigate("/loginmh")}
                >
                  Đăng nhập để mua hàng
                </button>
              )}

              <div className="continue-shopping">
                <Link to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                  </svg>
                  <span>Tiếp tục mua hàng</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="khoang" style={{"height" : "452px"}}>               
        </div>  
    </div>
    </>

  );
};

export default Cart;

