import React from "react";

import { styled } from "styled-components";
import moment from 'moment';
import { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/scrollbar';

// import required modules


import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { url } from "./api";


const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return { ...state, loading: true };
        case "FETCH_SUCCESS":
            return { ...state, products: action.payload, loading: false };
        case "FETCH_FAIL":
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};


const FeatureProducts = () => {

    const [{ loading }, dispatch] = useReducer(reducer, {
        xklds: [],
        nhadats: [],
        loading: true,
        error: "",
    });
    console.log(loading)
    const [xkld, getXklds] = useState([]);
    const [nhadat, getNhadats] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: "FETCH_REQUEST" });
            try {
                const result = await axios.get(`${url}/xklds`);
                const resultd = await axios.get(`${url}/nhadats`);
                dispatch({ type: "FETCH_SUCCESS", payload: result.data });
                dispatch({ type: "FETCH_SUCCESS", payload: resultd.data });
                getXklds(result.data);
                getNhadats(resultd.data)

            } catch (err) {
                dispatch({ type: "FETCH_FAIL", payload: err.message });
            }


        };
        fetchData();
    }, []);


    return (


        <Wrapper>
            <div className="w3 container">
                <Row>

                      <Swiper
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            430: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 4,
                            },
                        }}
                        loop={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >


                        {
                            nhadat.map((xk) => {

                                return (
                                    <SwiperSlide >
                                        <NavLink to={`/singlenhadat/${xk._id}`}>
                                            <Col className="xk1"  >
                                                <Card style={{ margin: "2px" }}>
                                                    <Card.Img variant="top" src={xk.image[0].url} style={{ width: "100%" }} />
                                                    <Card.Body>
                                                        
                                                        <Card.Text>
                                                        <div className="w3 d-flex justify-content-between">
                                                            <b>{xk.named}</b>
                                                            <p style={{ color: "red", marginRight:"1px" }}><b>{xk.priced}</b><img src='https://res.cloudinary.com/dxnhv54sl/image/upload/v1704876493/logo/s3wudnqv1q2axmhfv6i7.png' alt='gi' style={{ width: "15px", marginLeft: "3px" }} /></p> 
                                                        </div>
                                                            
                                                            <div className="w3 d-flex justify-content-between">
                                                                <p style={{marginLeft:"1px"}}><img src='https://res.cloudinary.com/dxnhv54sl/image/upload/v1704877484/logo/odhwgvpihi660kyakyil.png' alt='gi' style={{ width: "15px", marginRight: "3px" }} />{xk.area}</p>
                                                                <p><img src='https://res.cloudinary.com/dxnhv54sl/image/upload/v1704878358/logo/njzejgkgyvhq9juank6e.png' alt='gi' style={{ width: "15px", marginRight: "3px" }} />{xk.area}</p>
                                                                <p style={{marginRight:"1px"}}>{xk.huongd}<img src='https://res.cloudinary.com/dxnhv54sl/image/upload/v1704888598/logo/mupj5awrdva2dikb52ac.png' alt='gi' style={{ width: "15px", marginLeft: "3px" }} /></p>

                                                            </div>
                                                            <div className="w3 d-flex justify-content-between">
                                                                <p style={{ fontSize: "12px" }}> <i><img src='https://res.cloudinary.com/dxnhv54sl/image/upload/v1704875724/logo/kbitiot4dn4mnytqpkbf.png' alt="ew" style={{ width: "12px", marginRight: "3px" }} />  {moment(xk.createdAt).format('D/M/Y')}</i></p>
                                                                <p>{xk.address} <img src='https://res.cloudinary.com/dxnhv54sl/image/upload/v1704889977/logo/tn0crkpfeqida9lp3vzj.png' alt='gi' style={{ width: "12px", marginLeft: "3px" }} /></p>
                                                                
                                                            </div>
                                                        </Card.Text>

                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </NavLink>

                                    </SwiperSlide>

                                )
                            })

                        }
                    </Swiper>
                </Row>
                <Row >
                    <div className="w3 container banner " style={{position:"relative"}}>
                        <img className="w3-image " src="https://res.cloudinary.com/dxnhv54sl/image/upload/v1704893473/logo/sy9koxesb93m1gcxstjq.png" alt="ds"/>
                        <img className="anhgoc" style={{position:"absolute", bottom:"0", right:"0"}} src="https://res.cloudinary.com/dxnhv54sl/image/upload/v1704898403/logo/hderfazf1lho6vt93h8s.png" alt="ds"/>
                        <div className="w3 text-center bannertext" style={{position:"absolute",top:"1.5rem", left:"0", width:"100%", marginTop:"auto", marginBottom:"auto"}}>
                            <p id="p1" style={{color:"white", fontWeight:"bold", fontSize:"1.5vw", marginRight:"100px"}}>KẾT NỐI</p>
                            <p id="p11" style={{color:"#ffc107", fontWeight:"bold",fontSize:"2vw", marginRight:"100px"}}>MÔI GIỚI BẤT ĐỘNG SẢN</p>
                            <p id="p111" style={{color:"#ffc107", fontWeight:"bold",fontSize:"2vw", marginRight:"100px"}}>DỊCH VỤ XUẤT KHẨU LAO ĐỘNG</p>
                            <p id="p2" style={{color:"white",fontWeight:"700",fontSize:"1.5vw", marginRight:"100px"}}>UY TÍN, BẢO MẬT</p>
                        </div>

                    </div>
                    
                    <Swiper
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            430: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 4,
                            },
                        }}
                        loopc={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >

                        {
                            xkld.map((xk) => {

                                return (
                                    <SwiperSlide >
                                        <NavLink to={`/singlexkld/${xk._id}`}>
                                            <Col className="xk1"  >
                                                <Card style={{ margin: "2px" }}>
                                                    <Card.Img variant="top" src={xk.image[0].url} style={{ width: "100%" }} />
                                                    <Card.Body>
                                                        <Card.Title>Tuyển: <b style={{ color: "red" }}> {xk.namex}</b></Card.Title>
                                                        <Card.Text>
                                                            <div className="w3 d-flex justify-content-between">
                                                                <p style={{fontSize:"11px"}}>Nước đến: {xk.categoryx}</p>
                                                                <p style={{fontSize:"11px"}}>Lương: <b style={{ color: "red", fontSize:"11px" }}>{xk.salaryx}</b></p>
                                                            </div>

                                                            <p><i>{xk.descriptionx?.slice(0, 30)}...</i></p>
                                                            <p style={{ fontSize: "12px", marginLeft:"-3px" }}> <i><img src='https://res.cloudinary.com/dxnhv54sl/image/upload/v1704875724/logo/kbitiot4dn4mnytqpkbf.png' alt="ew" style={{ width: "12px", marginRight: "3px" }} />  {moment(xk.createdAt).format('D/M/Y')}</i></p>
                                                        </Card.Text>

                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </NavLink>

                                    </SwiperSlide>

                                )
                            })
                        }
                    </Swiper>
                </Row>

            </div>


        </Wrapper >


    )
};

export default FeatureProducts;

const Wrapper = styled.section`

@media screen and (max-width: 431px) {
    div.banner{
        margin-top:10px;
        margin-bottom:10px;
    }
   
   



}
@media screen and (max-width:768px) {
   
    .banner{
        margin-top:10px;
        margin-bottom:10px;
    }
}
    @media screen and (max-width:820px) {
       img.anhgoc {
            display:none;
        
        }

        #p1,#p11,#p111,#p2{
            margin-top:2px;
            margin-bottom:3px;
            
            font-family: -webkit-body;
        }
        .banner{
            margin-top:10px;
            margin-bottom:10px;
        }
       
   
   
    }
    @media screen and (max-width:1024px) {
       .anhgoc {
           
            width:150px;
        
        }
       
    }
    .anhgoc {
           
        width:550px;
    
    }    
       
    @media screen and (max-width:920px) {
        .anhgoc {
            
             width:150px;
         
         }
        
     }
     .anhgoc {
            
         width:550px;
     
     }    
   
     .banner{
        margin-top:80px;
        margin-bottom:1rem;
    }
    
    #p1,#p11,#p111,#p2{
        margin-top:2px;
        margin-bottom:3px;
        
        font-family: -webkit-body;
    }
    
#h3tr{
    margin-top:20px;
    
    font-size:20px;
    text-transform: capitalize;
 
  color: #285ea6;
}
    
    
    padding: 10px 0px;
    
    figure {
        margin: unset;
    }
    
    
    figure, .xk1 {
        z-index:1;
        margin:2px 2px;
        width: auto;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        transition: 0.8s ease all;
        cursor: pointer;
        &:hover img {
            
            -webkit-transform: scale(1.1,1.1);
            max-width: 100%;
            opacity:0.8;
           
            
        }
        &::after{
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 0%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            transition: all 0.2s linear 0s;
            cursor: pointer;
        }
        img{
            margin:5px 2px;
            max-width: 95%;
            
            height: auto;
            transition: all 0.2s linear 0s;
            
        }
    
        }
    .btnRe{
        background-color:rgb(0 0 0 /0%);
        border: 0.1rem solid rgb(98 84 243); 
        color: gray;
    }
    .btnRe:hover{
        background-color: ${({ theme }) => theme.colors.btn};
        color:white;
      }    

`;